<template>
<div>
    <!-- Report Details -->
    <div class="vx-row">
        <div class="vx-col w-full mt-8">
            <vx-card>
                <div class="card-title">
                    <h2>Update My Notification Rules</h2>
                    <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn"></vs-button> -->
                </div>
                <div class="notification-rule">
                    <div class="notification-details">
                        <div class="vx-row">
                            <div class="vx-col w-full flex flex-wrap justify-between">
                                <h3 class="float-left w-auto mt-4">Please select your notification and reporting options.</h3>
                                <div class="float-right mb-5 sm:w-auto w-full">
                                    <vs-button color="grey" type="border" class="ml-0 float-right sm:ml-4 sm:w-auto w-full mb-4 sm:mb-0 grey-btn" @click="getNotificationRule()">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                    <vs-button type="filled" class="float-right sm:w-auto w-full" @click="updateNotificationRule()" color="primary">{{ LabelConstant.buttonLabelUpdateMyNotificationRules }}</vs-button>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mb-8">
                                <div class="notification-item">
                                    <vs-checkbox color="secondary" v-model="notificationEnabled">Send Instant Email Alerts</vs-checkbox>
                                    <ul>
                                        <li>
                                            <span>Send an email whenever a visitor views</span>
                                            <!--  <v-select
                          v-model="notifyNumberPageVisits"
                          :options="emailReportoptions"
                          :clearable="false"
                        />-->
                                            <v-select
                                            v-model="notifyNumberPageVisits" :options="instantemailReportoptions" name="notifyNumberPageVisits" :reduce="instantemailReportoption => instantemailReportoption.value" label="label" :clearable="false" />
                                            <span>pages of your website.</span>
                                        </li>
                                        <li>
                                            <span>Do not send duplicate leads for</span>
                                            <!-- <v-select
                          v-model="notificationDuplicateLeadDelay"
                          :options="leadDayoptions"
                          :clearable="false"
                        />-->
                                            <v-select
                                            v-model="notificationDuplicateLeadDelay" :options="leadDayoptions" name="leadDayoptions" :reduce="leadDayoption => leadDayoption.value" label="label" :clearable="false" />
                                            <span>days.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mb-8">
                                <div class="notification-item">
                                    <vs-checkbox color="secondary" :disabled="isReportSettingsReadOnly" v-model="notifySendDailyReport">Send Daily Email Reports</vs-checkbox>
                                    <ul>
                                        <li v-if="!vendastaIsPartner">
                                            <span>Send daily reports showing the</span>
                                            <!--  <v-select
                          v-model="notifySendDailyReportNumber"
                          :options="dailyemailReportoptions"
                          :clearable="false"
                        />-->
                                            <v-select
                                            :disabled="isReportSettingsReadOnly"
                                            v-model="notifySendDailyReportNumber" :options="dailyemailReportoptions" name="notifySendDailyReportNumber" :reduce="dailyemailReportoption => dailyemailReportoption.value" label="label" :clearable="false" />
                                            <span>most active visitors each day.</span>
                                        </li>
                                        <li>
                                            <p>Daily reports are sent at approximately 3:00am Eastern Time and describe the previous day's activity.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mb-8">
                                <div class="notification-item">
                                    <vs-checkbox color="secondary" :disabled="isReportSettingsReadOnly" v-model="notifySendWeeklyReport">Send Weekly Email Reports</vs-checkbox>
                                    <ul>
                                        <li v-if="!vendastaIsPartner">
                                            <span>Send weekly report showing the</span>
                                            <!-- <v-select
                          v-model="notifySendWeeklyReportNumber"
                          :options="weeklyemailReportoptions"
                          :clearable="false"
                        />-->
                                            <v-select
                                            :disabled="isReportSettingsReadOnly"
                                            v-model="notifySendWeeklyReportNumber" :options="weeklyemailReportoptions" name="notifySendWeeklyReportNumber" :reduce="weeklyemailReportoption => weeklyemailReportoption.value" label="label" :clearable="false" />
                                            <span>most active visitors each week.</span>
                                        </li>
                                        <li>
                                            <p>Weekly reports are sent at approximately 3:00am Eastern Time every Monday and describe the previous week's activity.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mb-8" v-if="showNotificationRulesWatchListCard">
                                <div class="notification-item">
                                    <vs-checkbox color="secondary" v-model="notificationEnabledWatchList">Send Watch List Email Alerts</vs-checkbox>
                                    <ul>
                                        <li>
                                            <p>Send an email alert each time a company on my watch list visits my website.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mb-8" v-if="!vendastaIsPartner">
                                <div class="notification-item">
                                    <vs-checkbox color="secondary" v-model="notificationURLEnabled">Send URL Email Alerts</vs-checkbox>
                                    <ul>
                                        <li>
                                            <p>Watch these URLs and send an email if a {{  !isConsumer?'company':'visitor'}} lands on them. Even if none of the other rules match.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="notify-table" v-if="!vendastaIsPartner">
                        <div class="justify-start flex w-full">
                            <vs-button :title="LabelConstant.buttonLabelAddURLToWatch" @click="addpnewurlActive=true" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mb-4 bg-actionbutton">{{ LabelConstant.buttonLabelAddURLToWatch }}</vs-button>
                        </div>
                        <div class="common-table">
                            <!--Table Select Row-->
                            <div class="table-row-select">
                                <!-- <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-model="perPage" v-for="(item,index) in recordList" />
                                </vs-select> -->
                                <div class="custom-v-select con-select show-select">
                                    <label class="vs-select--label pl-0">Show Entries</label>
                                    <v-select
                                        class="show-select"
                                        label="text"
                                        :options="recordList"
                                        :reduce="(option) => option.value"
                                        :clearable="false"
                                        v-model="perPage"
                                    />
                                </div>
                            </div>
                            <vs-table :data="notifyUrl" search :max-items="perPage" pagination>
                                <template slot="thead">
                                    <vs-th></vs-th>
                                    <vs-th>Notify on these URLs</vs-th>
                                </template>
                                <template slot-scope="{data}">
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td width="50">
                                            <vs-button @click="deleteNotifyUrl(tr)" :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                        </vs-td>
                                        <vs-td :data="tr.Site_URL">{{tr.Site_URL}}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                    <div class="justify-end flex w-full flex-wrap" v-if="!vendastaIsPartner">
                        <vs-button type="filled" @click="updateNotificationRule()" class="sm:w-auto w-full mb-4 sm:mb-0" color="primary">{{ LabelConstant.buttonLabelUpdateMyNotificationRules }}</vs-button>
                        <vs-button color="grey" type="border" class="ml-0 sm:ml-4 sm:w-auto w-full grey-btn" @click="getNotificationRule()">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Delete Notification URL?</h4>
                    <span class="deleted-no">{{deleteUrl}}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="deleteNotificationUrl()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button @click="deletepopupActive = false, deleteUrl=null" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Url Modal -->
    <vs-popup class="tag-info new-notification" title="Create New Notification URL" :active.sync="addpnewurlActive">
        <form @submit.prevent="checkValidation()" autocomplete="off">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col input-text w-full">
                        <div class="popup-icon mb-10">
                            <img src="../../../src/assets/images/notification.svg" />
                        </div>
                        <p class="mb-4 mt-0">Notify me when someone visits this URL:</p>
                        <div class="http">
                            <span class="span-http">http://</span>
                            <vs-input class="w-full" placeholder="www.something.com/test.html" v-model="siteUrl" name="siteUrl" v-validate="{required:true,url:true }" />
                        </div>
                        <span class="text-danger text-sm">{{ errors.first('siteUrl') }}</span>
                    </div>
                </div>
            </template>

            <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
                <vs-button type="filled" button="submit" color="primary">{{ LabelConstant.buttonLabelCreateNotificationUrl }}</vs-button>
                <vs-button @click.prevent="cancelCreate()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </form>
    </vs-popup>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import vSelect from "vue-select";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        siteUrl: {
            required: "A valid URL is required.",
            url: "Please enter a valid URL."
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        vSelect,
        HelpModal
    },
    data() {
        return {
            deleteUrl: null,
            deletepopupActive: false,
            /* Popup */
            notificationEnabled: null,
            notifySendDailyReport: null,
            notifySendWeeklyReport: null,
            notificationEnabledWatchList: null,
            notificationURLEnabled: null,

            notifyNumberPageVisits: null,
            notificationDuplicateLeadDelay: null,
            notifySendDailyReportNumber: null,
            notifySendWeeklyReportNumber: null,

            popupActive: false,
            addpnewurlActive: false,
            siteUrl: null,
            instantEmail: false,
            dailyEmail: false,
            weeklyEmail: false,
            watchEmail: false,
            sendurlEmail: false,
            /* Select */
            instantemailReportoptions: [{
                    label: "1",
                    value: 1
                },
                {
                    label: "2",
                    value: 2
                },
                {
                    label: "3",
                    value: 3
                },
                {
                    label: "4",
                    value: 4
                },
                {
                    label: "5",
                    value: 5
                },
                {
                    label: "6",
                    value: 6
                },
                {
                    label: "7",
                    value: 7
                },
                {
                    label: "8",
                    value: 8
                },
                {
                    label: "9",
                    value: 9
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "11",
                    value: 11
                },
                {
                    label: "12",
                    value: 12
                },
                {
                    label: "13",
                    value: 13
                },
                {
                    label: "14",
                    value: 14
                },
                {
                    label: "15",
                    value: 15
                },
                {
                    label: "16",
                    value: 16
                },
                {
                    label: "17",
                    value: 17
                },
                {
                    label: "18",
                    value: 18
                },
                {
                    label: "19",
                    value: 19
                },
                {
                    label: "20",
                    value: 20
                },
                {
                    label: "21",
                    value: 21
                },
                {
                    label: "22",
                    value: 22
                },
                {
                    label: "23",
                    value: 23
                },
                {
                    label: "24",
                    value: 24
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "26",
                    value: 26
                },
                {
                    label: "27",
                    value: 27
                },
                {
                    label: "28",
                    value: 28
                },
                {
                    label: "29",
                    value: 29
                },
                {
                    label: "30",
                    value: 30
                },
                {
                    label: "31",
                    value: 31
                },
                {
                    label: "32",
                    value: 32
                },
                {
                    label: "33",
                    value: 33
                },
                {
                    label: "34",
                    value: 34
                },
                {
                    label: "35",
                    value: 35
                },
                {
                    label: "36",
                    value: 36
                },
                {
                    label: "37",
                    value: 37
                },
                {
                    label: "38",
                    value: 38
                },
                {
                    label: "39",
                    value: 39
                },
                {
                    label: "40",
                    value: 40
                },
                {
                    label: "41",
                    value: 41
                },
                {
                    label: "42",
                    value: 42
                },
                {
                    label: "43",
                    value: 43
                },
                {
                    label: "44",
                    value: 44
                },
                {
                    label: "45",
                    value: 45
                },
                {
                    label: "46",
                    value: 46
                },
                {
                    label: "47",
                    value: 47
                },
                {
                    label: "48",
                    value: 48
                },
                {
                    label: "49",
                    value: 49
                },
                {
                    label: "50",
                    value: 50
                }
            ],
            emailReport: [],
            emailReportoptions: [{
                    label: "0",
                    value: 0
                },
                {
                    label: "1",
                    value: 1
                },
                {
                    label: "2",
                    value: 2
                },
                {
                    label: "3",
                    value: 3
                },
                {
                    label: "4",
                    value: 4
                },
                {
                    label: "5",
                    value: 5
                },
                {
                    label: "6",
                    value: 6
                },
                {
                    label: "7",
                    value: 7
                },
                {
                    label: "8",
                    value: 8
                },
                {
                    label: "9",
                    value: 9
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "11",
                    value: 11
                },
                {
                    label: "12",
                    value: 12
                },
                {
                    label: "13",
                    value: 13
                },
                {
                    label: "14",
                    value: 14
                },
                {
                    label: "15",
                    value: 15
                },
                {
                    label: "16",
                    value: 16
                },
                {
                    label: "17",
                    value: 17
                },
                {
                    label: "18",
                    value: 18
                },
                {
                    label: "19",
                    value: 19
                },
                {
                    label: "20",
                    value: 20
                },
                {
                    label: "21",
                    value: 21
                },
                {
                    label: "22",
                    value: 22
                },
                {
                    label: "23",
                    value: 23
                },
                {
                    label: "24",
                    value: 24
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "26",
                    value: 26
                },
                {
                    label: "27",
                    value: 27
                },
                {
                    label: "28",
                    value: 28
                },
                {
                    label: "29",
                    value: 29
                },
                {
                    label: "30",
                    value: 30
                },
                {
                    label: "31",
                    value: 31
                },
                {
                    label: "32",
                    value: 32
                },
                {
                    label: "33",
                    value: 33
                },
                {
                    label: "34",
                    value: 34
                },
                {
                    label: "35",
                    value: 35
                },
                {
                    label: "36",
                    value: 36
                },
                {
                    label: "37",
                    value: 37
                },
                {
                    label: "38",
                    value: 38
                },
                {
                    label: "39",
                    value: 39
                },
                {
                    label: "40",
                    value: 40
                },
                {
                    label: "41",
                    value: 41
                },
                {
                    label: "42",
                    value: 42
                },
                {
                    label: "43",
                    value: 43
                },
                {
                    label: "44",
                    value: 44
                },
                {
                    label: "45",
                    value: 45
                },
                {
                    label: "46",
                    value: 46
                },
                {
                    label: "47",
                    value: 47
                },
                {
                    label: "48",
                    value: 48
                },
                {
                    label: "49",
                    value: 49
                },
                {
                    label: "50",
                    value: 50
                }
            ],
            leadDay: [],
            leadDayoptions: [{
                    label: "1",
                    value: 1
                },
                {
                    label: "2",
                    value: 2
                },
                {
                    label: "3",
                    value: 3
                },
                {
                    label: "4",
                    value: 4
                },
                {
                    label: "5",
                    value: 5
                },
                {
                    label: "6",
                    value: 6
                },
                {
                    label: "7",
                    value: 7
                },
                {
                    label: "8",
                    value: 8
                },
                {
                    label: "9",
                    value: 9
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "11",
                    value: 11
                },
                {
                    label: "12",
                    value: 12
                },
                {
                    label: "13",
                    value: 13
                },
                {
                    label: "14",
                    value: 14
                },
                {
                    label: "15",
                    value: 15
                },
                {
                    label: "16",
                    value: 16
                },
                {
                    label: "17",
                    value: 17
                },
                {
                    label: "18",
                    value: 18
                },
                {
                    label: "19",
                    value: 19
                },
                {
                    label: "20",
                    value: 20
                },
                {
                    label: "21",
                    value: 21
                },
                {
                    label: "22",
                    value: 22
                },
                {
                    label: "23",
                    value: 23
                },
                {
                    label: "24",
                    value: 24
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "26",
                    value: 26
                },
                {
                    label: "27",
                    value: 27
                },
                {
                    label: "28",
                    value: 28
                },
                {
                    label: "29",
                    value: 29
                },
                {
                    label: "30",
                    value: 30
                }
            ],
            dailyemailReport: [],
            dailyemailReportoptions: [{
                    label: "10",
                    value: 10
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "50",
                    value: 50
                },
                {
                    label: "75",
                    value: 75
                },
                {
                    label: "100",
                    value: 100
                },
                {
                    label: "200",
                    value: 200
                }
            ],
            weeklyemailReport: [],
            weeklyemailReportoptions: [{
                    label: "10",
                    value: 10
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "50",
                    value: 50
                },
                {
                    label: "75",
                    value: 75
                },
                {
                    label: "100",
                    value: 100
                },
                {
                    label: "200",
                    value: 200
                }
            ],
            perPage: 5,
            recordList: [{
                    text: '5',
                    value: 5
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '25',
                    value: 25
                },
                {
                    text: '50',
                    value: 50
                },
                {
                    text: '100',
                    value: 100
                }
            ],
            /* Table */
            notifyUrl: [],

            showNotificationRulesWatchListCard:true,
            isConsumer:false,
            vendastaIsPartner: false,
            isReportSettingsReadOnly: false
        };
    },
    created() {
        this.getNotificationRuleUrl();
        this.getNotificationRule();
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isConsumer = token[uid].isConsumerPlan;
        let screen = token[uid].screenItems;
        const result = screen.find( ({ ScreenItemName }) => ScreenItemName === "leadhandling_notificationrules_watchlist_card" );
        this.showNotificationRulesWatchListCard = result.Visible;
        this.vendastaIsPartner = token[uid].vendastaIsPartner;
        this.isReportSettingsReadOnly = token[uid].isReportSettingsReadOnly;
    },
    methods: {
        // delete notify url popup
        async deleteNotifyUrl(url) {
            this.deleteUrl = url.Site_URL;
            this.deletepopupActive = true;
        },
        // when click on cancel button
        async cancelCreate() {
            this.siteUrl = null;
            this.addpnewurlActive = false;
            setTimeout(() => {
                this.errors.clear();
            }, 50);
        },
        // check validation
        async checkValidation() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    this.CreateNotificationRuleURL();
                }
            });
        },
        /* Popup Close */
        closepopup() {
            this.popupActive = false;
        },
        // get notification rule data
        async getNotificationRule() {
            this.$vs.loading();

            await this.axios
                .get("/ws/NotificationRule/GetNotificationRuleList")
                .then(response => {
                    let data = response.data;
                    this.notificationEnabled = data.Notification_Enabled;
                    this.notifySendDailyReport = data.Notify_Send_Daily_Report;
                    this.notifySendWeeklyReport = data.Notify_Send_Weekly_Report;
                    this.notificationEnabledWatchList =
                        data.Notification_Enabled_Watch_List;
                    this.notificationURLEnabled = data.Notification_URL_Enabled;
                    this.notifyNumberPageVisits = data.Notify_Number_Page_Visits;
                    this.notificationDuplicateLeadDelay =
                        data.Notification_Duplicate_Lead_Delay;
                    this.notifySendDailyReportNumber =
                        data.Notify_Send_Daily_Report_Number;

                    this.notifySendWeeklyReportNumber =
                        data.Notify_Send_Weekly_Report_Number;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get notification rule url
        async getNotificationRuleUrl() {
            this.$vs.loading();

            await this.axios
                .get("/ws/NotificationRule/GetNotificationRuleURLList")
                .then(response => {
                    let data = response.data;
                    this.notifyUrl = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // create notification rule url
        async CreateNotificationRuleURL() {
            this.$vs.loading();
            let createNotificationUrl = {
                Site_URL: this.siteUrl
            };
            await this.axios
                .post(
                    "/ws/NotificationRule/CreateNotificationRuleURL",
                    createNotificationUrl
                )
                .then(() => {
                    this.siteUrl = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.addpnewurlActive = false;
                    this.getNotificationRuleUrl();
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Notification rule url has been created successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        // update notification rule data
        async updateNotificationRule() {
            let updateRules = {
                Notification_Enabled: this.notificationEnabled,
                Notify_Send_Daily_Report: this.notifySendDailyReport,
                Notify_Send_Weekly_Report: this.notifySendWeeklyReport,
                Notification_Enabled_Watch_List: this.notificationEnabledWatchList,
                Notification_URL_Enabled: this.notificationURLEnabled,
                Notify_Number_Page_Visits: this.notifyNumberPageVisits,
                Notification_Duplicate_Lead_Delay: this.notificationDuplicateLeadDelay,
                Notify_Send_Daily_Report_Number: this.notifySendDailyReportNumber,
                Notify_Send_Weekly_Report_Number: this.notifySendWeeklyReportNumber
            };
            await this.axios
                .post("/ws/NotificationRule/UpdateNotificationRules", updateRules)
                .then(() => {
                    this.siteUrl = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.addpnewurlActive = false;
                    this.getNotificationRule();
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Your information has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        // delete notification url
        async deleteNotificationUrl() {
            this.$vs.loading();
            let deleteNotificationUrl = {
                Site_URL: this.deleteUrl
            };
            await this.axios
                .post(
                    "/ws/NotificationRule/DeleteNotificationRuleURL",
                    deleteNotificationUrl
                )
                .then(() => {
                    this.deleteUrl = null;

                    this.deletepopupActive = false;
                    this.getNotificationRuleUrl();
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "URL has been removed from your notification list.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        }
    }
};
</script>
